<template>
    <div>
      <b-card>
        <h4 class="text-primary text-center mb-2"> Select Assessment</h4>
        <hr>
        <b-row class="text-secondary font-weight-bolder">
            <b-col md="6" sm="6"> Title </b-col>
            <b-col md="2" sm="2"> Start Date</b-col>
            <b-col md="2" sm="2"> End Date</b-col>
            <b-col md="2" sm="2"> </b-col>
            
        </b-row>
        <hr>
        <div v-for="(assessment, index) in assessments" :key="index">
            <b-row >
                <b-col md="6" sm="6" class="text-primary font-weight-bolder mt-1" @click="selectUserForAssessment(assessment)"><u>{{ assessment.title }}</u> </b-col>
                <b-col md="2" sm="3" class="text-info mt-1">{{ assessment.start_date }}</b-col>
                <b-col md="2" sm="3" class="text-info mt-1">{{ assessment.end_date }}</b-col>
                <b-col md="2" sm="3" class="text-info"><b-button variant="primary" @click="selectUserForAssessment(assessment)">SELECT</b-button></b-col>
            </b-row>
            <hr>
        </div>
      </b-card>
      <b-card v-if="isAssessmentSelected">
        <app-collapse>
            <app-collapse-item title="Click for Assessment Status" class="text-primary">
                <b-table
                    :items="userAssessmentStatus"
                    responsive
                    :fields="userAssessmentStatusFields"
                    class="mb-0"
                >
                    <template #cell(name)="data">
                        <div class="d-flex flex-column">
                            <a @click="getAssessmentQuestions(data.item.emp_id)">
                                <span class="font-weight-bolder text-primary mb-25"><u>{{ data.item.name }}</u></span>
                            </a>
                        </div>
                    </template>
                    <template #cell(manager)="data">
                        <div class="d-flex flex-column">
                            <span class="font-weight-bolder mb-25">{{ data.item.manager }}</span>
                        </div>
                    </template>
                    <template #cell(status)="data">
                        <div class="d-flex flex-column">
                            <span class="font-weight-bolder mb-25">{{ data.item.status }}</span>
                        </div>
                    </template>
                    <template #cell(rating)="data">
                        <div class="d-flex flex-column">
                            <span class="font-weight-bolder mb-25">{{ data.item.rating }}</span>
                        </div>
                    </template>
                </b-table>
            </app-collapse-item>    
        </app-collapse>

      </b-card>
      <b-card v-if="userList && (role === 'admin' || role === 'master' || role === 'account-manager')">
        <b-row>
            <b-col md="4" sm="4" class="text-primary font-weight-bolder mt-0">
                Select User
            </b-col>
            <b-col md="4" sm="4">
                <v-select
                    id="user-list"
                    label="title"
                    placeholder="Select User"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    v-model="selectedUser"
                    :options="userList"
                    :reduce="(option) => option.emp_id"
                />
            </b-col>
            <b-col md="4" sm="4" class="text-center">
                <b-button variant="primary" @click="getAssessmentQuestions(selectedUser)">DONE</b-button>
            </b-col>
        </b-row>
      </b-card>
      <div v-if="questions">
        <div v-if="role === 'admin' || role === 'talent-advisor-sourcer' || role === 'talent-advisor' || role === 'account-manager' || (role === 'master' && (assessmentData.isRecruiterDone || assessmentData.isTLDone))">
        <!-- <div v-if="role === 'admin' || role === 'talent-advisor-sourcer' || role === 'account-manager' || (role === 'master' && isTLDone && assessmentData.status === 'final')"> -->
        <div v-if="role === 'admin' || emp_id === assessmentData.emp_id || (role === 'account-manager' && emp_id !== assessmentData.emp_id && assessmentData.isRecruiterDone ) || (role === 'master' && (assessmentData.isRecruiterDone || assessmentData.isTLDone) )">
        <!-- <div class="demo-inline-spacing mb-2">
        </div> -->
        
        <b-card v-if="((role === 'talent-advisor-sourcer' || role === 'talent-advisor') && assessmentData.isRecruiterDone) || (role === 'account-manager' && assessmentData.isTLDone) || ((role === 'admin' || role === 'master') && assessmentData.isFinalDone)">
            <p class="text-secondary ">Rating: 
                <span class="text-primary font-weight-bolder" v-if="assessmentData.isFinalDone && assessmentData.status === 'final' && assessmentData.rating">{{ assessmentData.rating }} &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;===> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                    <span v-if="assessmentData.rating.includes('P1')"> Performance Below Expectations &nbsp; &nbsp; &nbsp; & &nbsp; &nbsp; &nbsp;</span>
                    <span v-if="assessmentData.rating.includes('P2')"> Performance Expectations Met &nbsp; &nbsp; &nbsp; & &nbsp; &nbsp; &nbsp;</span>
                    <span v-if="assessmentData.rating.includes('P3')"> Performance Exceeds Expectations &nbsp; &nbsp; &nbsp; & &nbsp; &nbsp; &nbsp;</span>
                    <span v-if="assessmentData.rating.includes('P4')"> Outstanding Performance &nbsp; &nbsp; &nbsp; & &nbsp; &nbsp; &nbsp;</span>
                    <span v-if="assessmentData.rating.includes('H1')"> Hygiene Below Expectations </span>
                    <span v-if="assessmentData.rating.includes('H2')"> Hygiene Expectations Met </span>
                    <span v-if="assessmentData.rating.includes('H3')"> Hygiene Exceeds Expectations </span>
                    <span v-if="assessmentData.rating.includes('H4')"> Oustanding Hygiene </span>
                </span>
                
                <span class="text-warning" v-else> Rating will be declared after Final Review !!! </span>
            </p>
            <p class="text-secondary font-weight-bolder" v-if="assessmentData.comment">Feedback Comment: </p>
            <p>
                <span class="text-info" v-if="assessmentData.isFinalDone && assessmentData.status === 'final' && assessmentData.comment" v-html="assessmentData.comment"></span>
            </p>
        </b-card>
        
        <employee-info class="mb-2" :employee-info="empStats.info" ></employee-info>
        <employee-stats :employee-stats="empStats.statistics" :attendance="parseInt(empStats.info.attendance)"></employee-stats>
        <b-row>
            <b-col  class="text-right">
                <b-button class="mr-2 mb-2" type="submit" variant="info" v-if="!checkIsDisabled" @click="saveResponses">Save Form</b-button>
            </b-col>
        </b-row>
        <!-- <employee-evaluation :employee-evaluation="employeeEvaluation"></employee-evaluation> -->
        <b-form @submit.prevent="submitResponses">
        
            <b-card v-for="(question, index) in questions"
                        :key="index"
            >
            <h4 class="text-primary text-center mb-1">Section - {{ question.section }}</h4>
            <!-- {{ question.questions }} -->
            <div class="px-3 mb-2 text-center">
                <!-- <span class="mb-0 "> <strong>Average Rating for Performance Section: </strong></span> -->
            </div>
            <!-- <ValidationObserver v-slot="{ invalid }"> -->
                
            <div
                v-for="(question, index) in question.questions"
                :key="index"
            >   
                <b-row class="mb-2">
                    <b-col cols="12" class="mb-1">
                        <strong>{{ question.text }}</strong>
                    </b-col>
                        <b-col cols="12" class="px-3 mb-0" v-if="assessmentLevel === 3 && (role === 'admin' || role === 'talent-advisor-sourcer' || role === 'talent-advisor' || (role === 'account-manager' && emp_id === assessmentData.emp_id) || (role === 'account-manager' && assessmentData.isRecruiterDone) || (role === 'master' && (assessmentData.isRecruiterDone || assessmentData.isTLDone)))">
                            <span class="mb-0" v-if="isTeamLeaderOrManager || isManager"> Recruiter Response</span>
                            <!-- Rating Dropdown -->
                            <b-col cols="12" class="mb-0">
                                <b-form-group>
                                    <b-form-radio-group
                                        v-model="question.rating"
                                        :options="ratingOptions"
                                        class="demo-inline-spacing"
                                        :name="'radio-inline-rec-' + question.id"
                                        :disabled="assessmentData.isRecruiterDone"
                                        value-field="value"
                                        text-field="text"   
                                    />
                                </b-form-group>
                            </b-col>
                            <!-- Comment Box -->
                            <b-col cols="12" class="mb-0">
                                <b-form-group>
                                    <b-form-textarea 
                                        v-model="question.comment" 
                                        :disabled="assessmentData.isRecruiterDone"
                                        placeholder="Add Comment upto 300 characters">
                                    </b-form-textarea>
                                </b-form-group>
                            </b-col>
                        </b-col>
                        <!-- <b-col cols="12" class="px-3 mb-0" v-if="role === 'admin' || (role === 'talent-advisor-sourcer' && assessmentData.isTLDone) || (role === 'account-manager' && (assessmentData.isRecruiterDone || emp_id === assessmentData.emp_id)) || (role === 'master' && assessmentData.isTLDone)"> -->
                        <b-col cols="12" class="px-3 mb-0" v-if="role === 'admin' || (role === 'account-manager' && !assessmentData.isTLDone && (assessmentData.isRecruiterDone || emp_id === assessmentData.emp_id)) || (role === 'master' && (assessmentData.isRecruiterDone || assessmentData.isTLDone))">
                            <span class="mb-0" v-if="isTeamLeaderOrManager || isManager"> Team Leader Response</span>
                            <!-- Rating Dropdown -->
                            <b-col cols="12" class="mb-0">
                                <b-form-group>                                           
                                    <b-form-radio-group
                                        v-model="question.teamLeadRating"
                                        :options="ratingOptions"
                                        @change="display"
                                        class="demo-inline-spacing"
                                        :name="'radio-inline-team-' + question.id"
                                        :disabled="assessmentData.isTLDone"
                                        value-field="value"
                                        text-field="text"    
                                    />
                                </b-form-group>
                            </b-col>
                            <!-- Comment Box -->
                            <b-col cols="12" class="mb-0">
                                <b-form-group>
                                    <b-form-textarea
                                        v-model="question.teamLeadComment" 
                                        :disabled="assessmentData.isTLDone"
                                        placeholder="Add Comment upto 300 characters">
                                    </b-form-textarea>
                                </b-form-group>
                            </b-col>
                        </b-col>
                        <!-- <b-col cols="12" class="px-3 mb-0" v-if="role === 'admin' || (role === 'talent-advisor-sourcer' && assessmentData.isFinalDone) || (role === 'account-manager' && assessmentData.isFinalDone) || (role === 'master' && assessmentData.isTLDone)"> -->
                        <b-col cols="12" class="px-3 mb-0" v-if="role === 'admin' || (role === 'master' && (assessmentData.isTLDone || assessmentData.isRecruiterDone))">
                            <span class="mb-0"> Final Response</span>
                            <!-- Rating Dropdown -->
                            <b-col cols="12" class="mb-0">
                                <b-form-group>                                        
                                    <b-form-radio-group
                                        v-model="question.finalRating"
                                        :options="ratingOptions"
                                        class="demo-inline-spacing"
                                        :name="'radio-inline-final-' + question.id"
                                        
                                        value-field="value"
                                        text-field="text"    
                                    />
                                </b-form-group>
                            </b-col>
                            <!-- Comment Box -->
                            <b-col cols="12" class="mb-0">
                                <b-form-group>
                                    <b-form-textarea 
                                        v-model="question.finalComment" 
                                        
                                        placeholder="Add Comment upto 300 characters">
                                    </b-form-textarea>
                                </b-form-group>
                            </b-col>
                        </b-col>
                </b-row>
            </div>
                    
                    <!-- Submit Button -->
            <!-- </ValidationObserver> -->
        </b-card>
        <div class="text-center">
            <!-- <b-button class="mr-2" type="submit" variant="info">Save Form</b-button> -->
            <b-button type="submit" variant="primary" v-if="role === 'admin' || role === 'master'">Submit & Rate</b-button>
            <b-button type="submit" variant="primary" :disabled="checkIsDisabled" v-else>Submit</b-button>
        </div>
        </b-form>
        </div>
        
        <div class="text-danger text-center font-weight-bolder" v-else>
            <span >Recruiter Response Still Pending OR Team Leader Review Window Not Yet Started !!!</span>
        </div>
        </div>
        <div class="text-danger text-center font-weight-bolder" v-else>
            <span >Team Leader Response Still Pending OR Final Review Window Not Yet Started !!!</span>
        </div>
      </div>
      <div v-if="assessmentData">
      <b-card v-if="(isfinalRatingStarted || rating || comment || assessmentData.isFinalDone) && (role === 'admin' || role === 'master')" class="mt-2" id="finalratingform">
        <h4 class="text-primary text-center">Submit final Rating and comment</h4>
        <div class="demo-inline-spacing" v-if="calculatedFinalRating">
            <span class="font-weight-bolder" v-if="calculatedFinalRating.recruiter">Recruiter Rating: <span class="text-primary">{{ calculatedFinalRating.recruiter }}</span></span>
            <span class="font-weight-bolder" v-if="calculatedFinalRating.teamlead">Team Lead Rating: <span class="text-primary">{{ calculatedFinalRating.teamlead }}</span></span>
            <span class="font-weight-bolder" v-if="calculatedFinalRating.final">Rating Based on final Reponses: <span class="text-primary">{{ calculatedFinalRating.final }}</span></span>
            <span class="font-weight-bolder" v-if="calculatedFinalRating.final">Final Rating: <span class="text-primary">{{ calculatedFinalRating.final }}</span></span>
        </div>
        
        <b-form @submit.prevent="submitFinalRating">
            
            <div class="demo-inline-spacing">
                <b-form-group
                >
                    <v-select
                        style="width: 300px"
                        id="performance-rating"
                        label="text"
                        placeholder="Select Performance Rating"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        v-model="selectedPerformanceRating"
                        :options="performanceRatingOptions"
                    />
                </b-form-group>
                <b-form-group
                >
                    <v-select
                    style="width: 300px"
                    id="hygiene-rating"
                    label="text"
                    placeholder="Select Hygiene Rating"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    v-model="selectedHygieneRating"
                    :options="hygieneRatingOptions"
                />
                </b-form-group>
                <span class="font-weight-bolder mb-1">Selected Rating: <span class="text-primary">{{ this.selectedPerformanceRating }}{{ this.selectedHygieneRating }}</span></span>
            </div>
            <b-form-group>
                <quill-editor
                  v-model="comment"
                  :options="snowOption"
                  ref="final-comment"
                  style="height: 300px"
                />
            </b-form-group>
            <b-button type="submit" variant="primary" class="float-right mt-3" @click="submitFinalRating">Submit</b-button>
        </b-form>

      </b-card>
      </div>
      <!-- <div v-else>
            <span class="text-danger">No Questions Found for Selected Assessment</span>
      </div>   -->
      
    </div>
  </template>
  
  <script>
  import {
  BForm,
  BFormGroup,
  BFormRadioGroup,
  BFormRadio,
  BFormInvalidFeedback,
  BCard,
  BRow,
  BCol,
  BFormTextarea,
  BButton,
  BTable,
} from "bootstrap-vue";
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
  import { ValidationProvider, ValidationObserver } from "vee-validate";
  import EmployeeStats from './EmployeeStats.vue';
  import EmployeeInfo from './EmployeeInfo.vue';
  import EmployeeEvaluation from './EmployeeEvaluation.vue';
  import RatingDropdown from './RatingDropdown.vue';
  import { quillEditor } from 'vue-quill-editor'
  import assessmentService from "@/services/assessment-service";
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
  import vSelect from "vue-select";

  export default {
    components: {
        EmployeeStats,
        EmployeeInfo,
        EmployeeEvaluation,
        RatingDropdown,
        BButton,
        BForm,
        BFormGroup,
        BFormRadioGroup,
        BFormRadio,
        // BFormInvalidFeedback,
        BCard,
        BRow,
        BCol,
        BFormTextarea,
        quillEditor,
        BTable,
        AppCollapse,
        AppCollapseItem,
        // Form Validation
        // ValidationProvider,
        // ValidationObserver,
        vSelect
    },
    data() {
      return {
        userStats: {}, // Fetch user stats from the backend
        questions: null,
        userAssessmentStatusFields: [
          { key: 'name', label: 'Name' },
          { key: 'manager', label: 'Manager' },
          { key: 'status', label: 'Status' },
          { key: 'rating', label: 'Final Rating' },
        ],
        // questions: [{ "qid": 1,text: 'How well do you rate yourself in generating revenue or contributing to the total revenue?', category: "Performance"},
        //     { "qid": 2,text: 'How well do you rate yourself in Profile Screening and JD study?', category: "Performance"},
        //     { "qid": 3,text: 'How well do you rate yourself in being versatile in sourcing?', category: "Performance"},
        //     { "qid": 4,text: 'How well do you rate yourself in building a rapport with your candidate and avoid having a drop?', category: "Performance"},
        //     { "qid": 5,text: 'How well do you rate yourself in sharing required number of CVs?', category: "Performance"}
        // ], // Fetch questions from the backend
        responses: null,
        empStats: null,
        // employeeStats: [
        //     {
        //         "parameter": "Revenue Generation",
        //         "avatar": "TrendingUpIcon",
        //         "actual_year": 200000,
        //         "expected_year": 250000,
        //         "actual_month": 200000,
        //         "expected_month": 250000
        //     },
        //     {
        //         "parameter": "Average profiles Shared Daily",
        //         "avatar": "UsersIcon",
        //         "actual_year": 10.5,
        //         "expected_year": 10,
        //         "actual_month": 10.5,
        //         "expected_month": 10
        //     },
        //     {
        //         "parameter": "Joiners per month",
        //         "avatar": "AwardIcon",
        //         "actual_year": 24,
        //         "expected_year": 48,
        //         "actual_month": 3,
        //         "expected_month": 72
        //     },
        //     {
        //         "parameter": "Selects per month",
        //         "avatar": "UserCheckIcon",
        //         "actual_year": 32,
        //         "expected_year": 72,
        //         "actual_month": 3,
        //         "expected_month": 72,
        //     },
        //     {
        //         "parameter": "Drops per Month",
        //         "avatar": "UserXIcon",
        //         "actual_year": 0.25,
        //         "expected_year": 0,
        //         "actual_month": 0.25,
        //         "expected_month": 0
        //     }
        // ],
        employeeStats: null,
        isRecruiterOrHigher: false,
        isManager: false,
        isTeamLeaderOrManager: false,
        employeeEvaluation: {
            rating: null,
            evaluation: []
        },
        ratingOptions: [
          { text: 'Below Expectations', value: { text: 'Below Expectations', value: 2 } },
          { text: 'Expectations Met', value: { text: 'Expectations Met', value: 3 } },
          { text: 'Exceeds Expectations', value: { text: 'Exceeds Expectations', value: 4 } },
          { text: 'Outstanding', value: { text: 'Outstanding', value: 5 } }
        ],
        snowOption: {
            theme: 'snow',
        },
        errors: null,  // Initialize with empty objects based on the number of questions,
        assessments: null,
        role: null,
        emp_id: null,
        name: null,
        userList: null,
        assessmentData: null,
        selectedUser: null,
        assessmentId: null,
        assessmentLevel: null,
        assessmentWeight: null,
        userAssessmentList: null,
        userAssessmentStatus: null,
        isfinalRatingStarted: false,
        calculatedFinalRating: null,
        finalResponses: null,
        rating: null,
        comment: null,
        selectedPerformanceRating: null,
        selectedHygieneRating: null,
        performanceRatingOptions: ['P1','P2','P3','P4','P5'],
        hygieneRatingOptions: ['H1','H2','H3','H4','H5'],
        isAssessmentSelected: false,
        //checkIsDisabled: false,

      };
    },
    computed: {
        checkIsDisabled() {
            if((this.role === 'talent-advisor-sourcer' || this.role === 'talent-advisor') && this.assessmentData.isRecruiterDone) {
                return true
            } else if(this.role === 'account-manager' && this.assessmentData.isTLDone) {
                return true
            }
            return false
        }
    },
    created() {
        console.log('in created: ', this.role)
      // Fetch questions and user stats on component creation
      const userData = JSON.parse(localStorage.getItem('userData'));
      this.emp_id = userData.emp_id
      this.role = userData.roles[0].toLowerCase()
      this.name = userData.first_name + ' ' + userData.last_name

      this.getActiveAssessments()
      if (this.role === 'admin' || this.role === 'master' || this.role === 'hr') {
        this.isManager = true
        this.isTeamLeaderOrManager = true
        this.isRecruiterOrHigher = true
    } else if (this.role === 'account-manager' || this.role === 'admin' || this.role === 'master' || this.role === 'hr') {
        this.isTeamLeaderOrManager = true
        this.isRecruiterOrHigher = true
    } else if (this.role === 'talent-advisor-sourcer' || this.role === 'talent-advisor' || this.role === 'account-manager' || this.role === 'admin' || this.role === 'master' || role === 'hr') {
        this.isRecruiterOrHigher = true
      }
      //this.employeeEvaluation = this.calcRating()
    },
    methods: {
    //   submitForm() {
    //     // Handle form submission
    //   },
      isDisabled(role,currentStage) {
        const order = ['recruiter', 'teamLeader', 'final'];
        //console.log('is disabled: ', role, currentStage, order.indexOf(currentStage), order.indexOf(role))
        return order.indexOf(currentStage) < order.indexOf(role);
      },
      display() {
        console.log('display: ',this.questions)
      },
      calcRating() {
        const evaluation = []
        this.responses.forEach(response => {
            const question = this.questions.filter(item => item.qid === response.qid)[0].text
            const category = this.questions.filter(item => item.qid === response.qid)[0].category

            const item = {
                question: question,
                empRating: response.rating,
                teamRating: response.teamLeadRating,
                finalRating: response.finalRating,
                category: category
            }

            evaluation.push(item)
            // const performanceEvaluation=  evaluation.filter(item => item.category === 'Performance')
            // const hygieneEvaluation=  evaluation.filter(item => item.category === 'Hygiene')

            // this.employeeEvaluation = {
            //     performance : performanceEvaluation,
            //     hygiene: hygieneEvaluation
            // }
        })
        let cumulativeScore = evaluation.reduce((total, question) => {
            let rating = this.ratingOptions.filter(option => option.text === question.finalRating)[0];
            return total + (rating ? rating.value : 0);
        }, 0);
        console.log('score: ', cumulativeScore)
        let averageScore = cumulativeScore / evaluation.length;
        console.log('avg score: ', averageScore)
        let closestRating = this.ratingOptions.reduce((prev, curr) => {
            return (Math.abs(curr.value - averageScore) < Math.abs(prev.value - averageScore) ? curr : prev);
        });

        let correspondingRating = closestRating.text;

        return {
            rating: correspondingRating,
            evaluation : evaluation
        }
        //console.log(this.employeeEvaluation)
      },
      getActiveAssessments() {
        assessmentService.getActiveAssessments(this.emp_id, this.role).then(res => {
            if (res.status === "OK") {
                this.assessments = res.data;
            } else if ((res.status === 401 ) || (res.status === 403 )) {
                window.sessionStorage.setItem('lastPage', window.location.href.split('/#')[1])
                localStorage.clear();
                this.$router.push({ name: "auth-login" });
            } else {
                alert("Error fetching active assessments" + res);
            }
        })
      },
      selectUserForAssessment(assessment) {
        this.isAssessmentSelected = true
        this.selectedUser = null
        this.assessmentId = assessment.id
        this.assessmentWeight = assessment.weight
        this.assessmentLevel = assessment.level
        assessmentService.getUserAssignedToAssessments(this.emp_id, assessment.id, this.role).then(res => {
            if (res.status === "OK") {
                this.userAssessmentList = res.data
                this.userAssessmentStatus = res.statusData
                console.log('Select user for assessment: ',this.userAssessmentList, this.userAssessmentStatus)

                //if(this.role === 'admin' || this.role === 'master' || this.role === 'account-manager') {
                if(this.role === 'admin' || this.role === 'master' || this.role === 'account-manager') {    
                    let allUsers = JSON.parse(localStorage.getItem('allusers'));

                    console.log('allUsers', allUsers)
                    this.userList = this.userAssessmentList.map(emp => {
                    // Find the title object in the titles array that matches the current employee's emp_id
                        const titleObj = allUsers.find(title => title.emp_id === emp.emp_id);
                        
                        // Return a new object with emp_id and title
                        // If titleObj is not found, return undefined for the title
                        return {
                            emp_id: emp.emp_id,
                            title: titleObj ? titleObj.title : undefined
                        };
                    });
                    // if(this.role === 'account-manager') {
                    //     this.userList.push({emp_id: this.emp_id, title: this.name})
                    // }
                    
                } else {
                    this.assessmentData = this.userAssessmentList[0]
                    this.getAssessmentQuestions(user)
                }
            }
        }) 
      },
      getAssessmentQuestions(user) {

        if(this.role === 'admin' || this.role === 'master' || this.role === 'account-manager') {
            console.log('Selected User', this.selectedUser, user)
            this.selectedUser = user
            this.assessmentData = this.userAssessmentList.filter(item => item.emp_id === user)[0]
            console.log('Selected assessment', this.assessmentData)
            this.comment = this.assessmentData.rating
            this.comment = this.assessmentData.comment
        } else {
            this.selectedUser = this.emp_id
        }
        assessmentService.getEmployeeStats(this.selectedUser).then(res => {
            if(res.status == "OK"){
                this.empStats = res.data
                console.log('Employee Stats: ', this.empStats)
            }
        })
        assessmentService.getAssessmentQuestions(this.selectedUser, this.assessmentId).then(res => {
            if (res.status == "OK"){
                const questions = res.data
                this.questions = []
                
                const uniqueCategories = questions.reduce((acc, question) => {
                    acc.add(question.category);
                    return acc;
                }, new Set());

                

                // Convert the Set back into an array
                const uniqueCategoriesArray = Array.from(uniqueCategories);

                console.log(uniqueCategoriesArray);

                uniqueCategoriesArray.forEach(category => {
                    let categoryQuestions = questions.filter(ques => ques.category === category);
                    console.log(categoryQuestions)
                    this.questions.push({
                        section: category.toUpperCase(),
                        questions: categoryQuestions,
                           
                    })
                    
                })
                if(this.role === 'admin' || this.role === 'master') {
                        const mergedQuestions = this.mergeQuestionsAndSelectedKeys(this.questions)
                        console.log('mergedQuestions from questions: ', mergedQuestions)
                        this.finalResponses = {}
                        this.finalResponses.responses = mergedQuestions
                        this.finalResponses.emp_id = this.selectedUser
                        this.finalResponses.assessment_id = this.assessmentId
                        this.finalResponses.updated_by = this.emp_id
                        this.finalResponses.assessmentLevel = this.assessmentLevel
                        this.finalResponses.role = this.role
                        
                    // this.finalResponses = this.getResponses('')
                    // console.log('responses from get assessment questions: ', this.finalResponses)
                    console.log('mergedQuestions:', mergedQuestions )
                    this.calculatedFinalRating = this.calculateFinalRating(mergedQuestions)
                }
                
            }
        })
      },
      saveResponses() {
        const response = this.getResponses('save')
        this.finalResponses = response
        assessmentService.updateAssessmentAndResponses(this.finalResponses).then(res => {
            if (res.status == "OK"){
                console.log(res)

                
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Responses save Successful',
                        icon: 'EditIcon',
                        variant: 'success',
                        timeout: 5000,
                },
              })
            }
        })
      },
      submitResponses() {
        const response = this.getResponses('submit')
        this.finalResponses = response
        if(this.role !== 'admin' && this.role !== 'master') {
            assessmentService.updateAssessmentAndResponses(this.finalResponses).then(res => {
                if (res.status == "OK"){
                    console.log(res)
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Responses Submit Successful',
                            icon: 'EditIcon',
                            variant: 'success',
                            timeout: 5000,
                        },
                })
                window.location.reload();
                }
            })
        }   
        
      },
      submitFinalResponses(response) {
        
        this.isfinalRatingStarted = true
        console.log('in final submit response: ',response)
        
        this.calculatedFinalRating = this.calculateFinalRating(response.responses)
        console.log('calculated Final Rating: ', this.calculatedFinalRating)
        this.scrollToElement()
        response.isFinalDone = true
        //response.added_by = this.emp_id
        //response.updated_by = this.emp_id
        this.finalResponses = response
        assessmentService.updateAssessmentAndResponses(this.finalResponses).then(res => {
            if (res.status == "OK"){
                console.log(res)
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Responses Submit Successful',
                        icon: 'EditIcon',
                        variant: 'success',
                        timeout: 5000,
                    },
              })
              //window.location.reload();
            }
        })
        
      },
      submitFinalRating() {
        console.log('final rating sumit method:', this.finalResponses, this.selectedPerformanceRating, this.selectedHygieneRating, this.comment)
        this.finalResponses.rating = this.selectedPerformanceRating + '' + this.selectedHygieneRating
        this.finalResponses.comment = this.comment
        console.log('final rating sumit method final:', this.finalResponses)
        assessmentService.updateAssessmentAndResponses(this.finalResponses).then(res => {
            if (res.status == "OK"){
                console.log(res)
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Responses Update Successful',
                        icon: 'EditIcon',
                        variant: 'success',
                        timeout: 5000,
                    },
              })
            }
        })
      },
      scrollToElement() {
        const el = 'finalratingform';
        this.$nextTick(() => {
            if (el) {
                document.getElementById(el).scrollIntoView();
            }
         //   document.getElementById('elementId').scrollIntoView();
        });
        
        console.log('element', el)
        
      },
      getResponses(type) {
        let finalResponse = {};
        const userData = JSON.parse(localStorage.getItem('userData'));
        const mgr_id = userData.mgr_id;
        const p_mgr_id = userData.p_mgr_id;
        console.log('Responses', this.questions, type)

        const mergedQuestions = this.mergeQuestionsAndSelectedKeys(this.questions)

        if(mergedQuestions){
            if(this.role === 'talent-advisor-sourcer' || this.role === 'talent-advisor') {
                finalResponse.responses = mergedQuestions
                finalResponse.mgr_id = mgr_id
                finalResponse.p_mgr_id = p_mgr_id
                finalResponse.emp_id = this.selectedUser
                finalResponse.assessment_id = this.assessmentId
                finalResponse.added_by = this.emp_id
                finalResponse.assessmentLevel = this.assessmentLevel
                finalResponse.role = this.role
                // this.finalResponses.type = type
                if(type === 'save') {
                    finalResponse.isRecruiterDone = false
                } else if(type === 'submit') {
                    finalResponse.isRecruiterDone = true
                }
                console.log(finalResponse)
            } else if(this.role === 'account-manager') {
                finalResponse.responses = mergedQuestions
                finalResponse.emp_id = this.selectedUser
                finalResponse.assessment_id = this.assessmentId
                finalResponse.updated_by = this.emp_id
                finalResponse.assessmentLevel = this.assessmentLevel
                finalResponse.role = this.role
                // this.finalResponses.type = type
                // if(this.emp_id === mgr_id && this.assessmentLevel === 2) {
                if(type === 'save') {
                    finalResponse.isTLDone = false
                } else if(type === 'submit') {
                    finalResponse.isTLDone = true
                }
                // } else if(this.emp_id === mgr_id && this.assessmentLevel === 2){

                // }
                console.log(finalResponse)
            } else if(this.role === 'admin' || this.role === 'master') {
                finalResponse.responses = mergedQuestions
                finalResponse.emp_id = this.selectedUser
                finalResponse.assessment_id = this.assessmentId
                finalResponse.updated_by = this.emp_id
                finalResponse.assessmentLevel = this.assessmentLevel
                finalResponse.role = this.role
                //this.finalResponses.type = type
                // if(this.emp_id === mgr_id && this.assessmentLevel === 2) {
                if(type === 'save') {
                    finalResponse.isFinalDone = false
                } else if(type === 'submit') {
                    this.submitFinalResponses(finalResponse)
                }
                // } else if(this.emp_id === mgr_id && this.assessmentLevel === 2){

                // }
                console.log(finalResponse)
            } 
        }
         return finalResponse;
      },
      mergeQuestionsAndSelectedKeys(array) {
        
        let mergedQuestions = null
        if(this.role === 'talent-advisor-sourcer' || this.role === 'talent-advisor') {
            mergedQuestions = array.reduce((acc, section) => {
            const selectedQuestions = section.questions.map(question => ({
                question_id: question.id,
                rating: question.rating,
                comment: question.comment,
                teamLeadRating: question.teamLeadRating,
                teamLeadComment: question.teamLeadComment,
                finalRating: question.finalRating,
                finalComment: question.finalComment,
                assessment_id: question.assessment_id
            }));
                return [...acc, ...selectedQuestions];
            }, []);
        } else if(this.role === 'account-manager') {
            mergedQuestions = array.reduce((acc, section) => {
            const selectedQuestions = section.questions.map(question => ({
                question_id: question.id,
                rating: question.rating,
                comment: question.comment,
                teamLeadRating: question.teamLeadRating,
                teamLeadComment: question.teamLeadComment,
                finalRating: question.finalRating,
                finalComment: question.finalComment,
                assessment_id: question.assessment_id
            }));
                return [...acc, ...selectedQuestions];
            }, []);
        } else if(this.role === 'admin' || this.role === 'master') {
            mergedQuestions = array.reduce((acc, section) => {
            const selectedQuestions = section.questions.map(question => ({
                question_id: question.id,
                rating: question.rating,
                comment: question.comment,
                teamLeadRating: question.teamLeadRating,
                teamLeadComment: question.teamLeadComment,
                category: question.category,
                finalRating: question.finalRating,
                finalComment: question.finalComment,
                assessment_id: question.assessment_id,
                final_rating_added_by: this.emp_id
            }));
                return [...acc, ...selectedQuestions];
            }, []);
        }
        return mergedQuestions;
      },
      // Assuming this is the structure of your responses


        // Function to calculate final rating
        calculateFinalRating(responses) {
            console.log('Reponses in calculate final rating: ', responses, this.assessmentData)
            let performanceSum = 0;
            let hygieneSum = 0;
            let performanceTLSum = 0;
            let hygieneTLSum = 0;
            let performanceRecSum = 0;
            let hygieneRecSum = 0;
            let calcRating = {  recruiter: null,
                                teamlead: null,
                                final: null
                            }

            responses.forEach(response => {
                //let value = 0
                //let valueTL = response.teamLeadRating.value;
                //let value = response.finalRating.value;
                // if(this.assessmentLevel === 3 && this.assessmentData.isRecruiterDone) {
                //     value = response.rating.value;
                // } else if(this.assessmentData.isTLDone) {
                //     value = response.teamLeadRating.value;
                // } else if(this.assessmentData.isFinalDone || this.isfinalRatingStarted) {
                //     value = response.finalRating.value
                // }
                if(this.assessmentLevel === 3 && this.assessmentData.isRecruiterDone) {
                    let valueRec = response.rating.value;
                    console.log('value: ', valueRec, response.category)
                    if (response.category === 'performance') {
                        performanceRecSum += valueRec * 2; // Multiply by 2 for performance category
                    } else if (response.category === 'hygiene') {
                        hygieneRecSum += valueRec; // Keep as is for hygiene category
                    }
                    const performanceRecRating = performanceRecSum > 40 ? 'P5' :
                                        performanceRecSum > 30 ? 'P4' :
                                        performanceRecSum > 20 ? 'P3' :
                                        performanceRecSum > 10 ? 'P2' : 'P1';

                    // Determine the hygiene rating
                    const hygieneRecRating = hygieneRecSum > 22 ? 'H5' :
                                            hygieneRecSum > 18 ? 'H4' :
                                            hygieneRecSum > 14 ? 'H3' :
                                            hygieneRecSum > 8 ? 'H2' : 'H1';  

                    calcRating.recruiter =  `${performanceRecRating}${hygieneRecRating}`
                }
                if(this.assessmentData.isFinalDone || this.isfinalRatingStarted) {
                    let value = response.finalRating.value;
                    console.log('value: ', value, response.category)
                    if (response.category === 'performance') {
                        performanceSum += value * 2; // Multiply by 2 for performance category
                    } else if (response.category === 'hygiene') {
                        hygieneSum += value; // Keep as is for hygiene category
                    }
                    const performanceRating = performanceSum > 40 ? 'P5' :
                                        performanceSum > 30 ? 'P4' :
                                        performanceSum > 20 ? 'P3' :
                                        performanceSum > 10 ? 'P2' : 'P1';

                    // Determine the hygiene rating
                    const hygieneRating = hygieneSum > 22 ? 'H5' :
                                            hygieneSum > 18 ? 'H4' :
                                            hygieneSum > 14 ? 'H3' :
                                            hygieneSum > 8 ? 'H2' : 'H1';  

                    calcRating.final =  `${performanceRating}${hygieneRating}`
                }
                
                if(this.assessmentData.isTLDone) {
                    let valueTL = response.teamLeadRating.value;
                    console.log('value: ', valueTL, response.category)
                    
                    if (response.category === 'performance') {
                        performanceTLSum += valueTL * 2; // Multiply by 2 for performance category
                    } else if (response.category === 'hygiene') {
                        hygieneTLSum += valueTL; // Keep as is for hygiene category
                    }
                    // Determine the performance rating
                    const performanceTLRating = performanceTLSum > 40 ? 'P5' :
                                                performanceTLSum > 30 ? 'P4' :
                                                performanceTLSum > 20 ? 'P3' :
                                                performanceTLSum > 10 ? 'P2' : 'P1';

                    // Determine the hygiene rating
                    const hygieneTLRating = hygieneTLSum > 22 ? 'H5' :
                                            hygieneTLSum > 18 ? 'H4' :
                                            hygieneTLSum > 14 ? 'H3' :
                                            hygieneTLSum > 8 ? 'H2' : 'H1';
                    
                    calcRating.teamlead =  `${performanceTLRating}${hygieneTLRating}`
                }
                
                
            });

        

            return calcRating;
        },
    },
    
  };
  </script>
  <style lang="scss">
  @import "@core/scss/vue/libs/vue-select.scss";
  @import '@core/scss/vue/libs/quill.scss';

  .collapse-title {
    text-decoration: underline;
    text-align: center;
    
  }
  </style>